<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="3">
        <v-card class="text-center ma-2">
          <h3 class="pb-3 pt-3"> {{ applicationCount }} </h3>
          <p class="pa-3 primary--text"> Applications </p>
          <v-btn text class="pb-4" :to="`/applications`"> View More </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Home',
  data: () => ({
    loading: true
  }),
  computed: mapState({
    applicationCount: state => state.application.count
  }),
  created () {
    this.$store.dispatch('application/getApplicationsCount')
  }
}
</script>
